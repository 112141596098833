@import '../../../index.scss';

.dx-datagrid-text-content {
  pointer-events:none;
}

.row {
  display: flex !important;
  align-items: center !important;
  width: 100%;
  overflow: auto;
  max-width: 100%;

  &::-webkit-scrollbar {
    display: none !important;
  }

}

.cell {
  box-sizing: border-box;
  display: flex !important;
  align-items: center !important;
  width: 150px;
  height: 65px;
  padding: 16px;
  padding-bottom: 0;
  white-space: nowrap;
  overflow: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none !important;
  }
}

.noData {
  color: rgba($color-black, 0.38) !important;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;

  & [class*="MuiTablePagination-toolbar"]{
    display: flex;
    align-items: center;
  }

  p {
    white-space: nowrap;
    width: auto;
    margin: 0 10px;
  }

  [class*="MuiTablePagination-actions"] {
    display: flex;
    gap: 5px;

    button{
      margin: 0;
    }
  }

  button {
    background-color: $color-primary-form ;
    color: $color-global-white;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    position: relative;

    &::before {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 22px;
      height: 22px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    &:hover {
      background-color: $color-primary-form-hover ;
    }

    &:active {
      background-color: $color-primary-form-active ;
    }

    &:disabled {
      background-color: rgba($color-primary-form, 0.3);

      &::before {
        opacity: 0.3;
      }
    }

    span {
      display: none;
    }
  }

  button[title*="next"]::before {
    background-image: url('../../../assets/icons/chevronRightFilled.svg');
  }

  button[title*="last"]::before {
    background-image: url('../../../assets/icons/skipNextFilled.svg');
  }

  button[title*="previous"]::before {
    background-image: url('../../../assets/icons/chevronRightFilled.svg');
    transform: scale(-1, 1) translate(50%, -50%);
  }

  button[title*="first"]::before {
    background-image: url('../../../assets/icons/skipNextFilled.svg');
    transform: scale(-1, 1) translate(50%, -50%);
  }

  select {
    padding: 4px;
    border-radius: 4px;
    border-color: $color-button;
  }
}

a {
  text-decoration: none;
}
