@import 'src/index.scss';

.recycleCard {
    width: 100%;
    overflow: hidden;
    border-radius: 30px;
    transition: max-height .3s ease-in;
    max-height: 240px;
    scroll-margin-top: 100px;
    &Expanded {
        max-height: 2100px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    @media screen and (min-width: 500px) {
        max-height: 100px;
        &Expanded {
            max-height: 2100px;
        }
    }
    @media screen and (min-width: 768px) {
        max-height: 124px;
        &Expanded {
            max-height: 2100px;
            border-radius: 30px;
        }
    }
    @media screen and (min-width: 1200px) {
        max-height: 123px;
        scroll-margin-top: 130px;
        &Expanded {
            max-height: 2100px;
        }
    }
}

.recycleCardHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $color-white;
    width: 100%;
    overflow: hidden;
    transition: none;
    cursor: pointer;
    @media screen and (min-width: 500px) {
        flex-direction: row;
        align-items: center;
    }
    @media screen and (min-width: 768px) {
        // column-gap: 30px;
    }
    @media screen and (min-width: 1200px) {
        // column-gap: 60px;
    }
}

.recycleCardImage {
    min-width: 270px;
    height: 150px;
    @media screen and (min-width: 500px) {
        min-width: auto;
        height: 100px;
        max-width: 180px;
    }
    @media screen and (min-width: 768px) {
        min-width: auto;
        height: 124px;
        max-width: 240px;
    }
    @media screen and (min-width: 1200px) {
        height: 123px;
        max-width: 300px;
    }
}

.recycleCardDescriptionWrapper {
    display: flex;
    width: 100%;
    min-height: 90px;
    @media screen and (min-width: 768px) {
        min-height: 124px;
    }
    @media screen and (min-width: 1200px) {
        min-height: 123px;
    }
}

.recycleCardDescription {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    gap: 10px;
    @media screen and (min-width: 768px) {
        height: auto;
        padding: 31px 30px 27px;
    }
    @media screen and (min-width: 1200px) {
        height: auto;
        padding-left: 60px;
        padding-right: 32px;
    }
}

.recycleCardInfo {
    display: inline-flex;
    max-width: calc(188 / 320 * 100vw);
    @media screen and (min-width: 768px) {
        max-width: 300px;
    }
    @media screen and (min-width: 1200px) {
        max-width: 581px;
    }
}

.recycleCardHeading {
    font-family: $font-primary;
    font-weight: 500;
    color: $color-black;
    margin: 0;
    font-size: 20px;
    line-height: 105%;
    @media screen and (min-width: 768px) {
        font-weight: 500;
        font-size: 30px;
        line-height: 33px;
    }
    @media screen and (min-width: 1200px) {
        font-weight: 500;
        font-size: 30px;
        line-height: 33px;
    }
}
