@import 'src/index.scss';

//to correct leaflet map z-index
//
//.leaflet-pane {
//    z-index: auto;
//}
//.leaflet-control {
//    z-index: auto;
//}
//.leaflet-top,
//.leaflet-bottom {
//    z-index: auto;
//}
//.leaflet-pane,
//.leaflet-map-pane
//{
//    transform: translate3d(0px, 0px, 0px) !important;
//}
//.leaflet-control {
//    z-index: auto;
//}
//.leaflet-top,
//.leaflet-bottom {
//    z-index: auto;
////}
.home-sticky-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.leaflet-popup-content {
    margin: 0px;

}

.leaflet-popup-content-wrapper {
    padding: 0px;
    border: 0px;
    border-radius: 20px;

}
.leaflet-popup-tip {
    background:#CAE3F9;
}

.leaflet-attribution-flag {
    display: none !important;
}

.marquee-container {
  height: 70px;
}

@media (max-width: 600px) {
  .marquee-container {
    height: 50px;
  }
}

.ticker {
  width: 100%;
  height: 70px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  border-width: 0.5px 0px;
  border-style: solid;
  border-color: $color-black;
  background-color: $color-white;
}

.footer {
  position: relative;
  // max-width: 1440px;
  height: 637px;
  padding-top: 43px;
  // margin: 0 auto;
  background-image: url(../../assets/images/footer_img.png);
  background-size: cover;
  background-position-y: bottom;
  background-repeat: no-repeat;
}
