@import "../../index.scss";

$duration: 3s;

.parallax {
    height: 2048px;
    overflow: hidden;
    position: relative;
    margin-top: -106px;
    transition: all $duration;
    &.active {
        margin-top: 0;
        height: 902px;
        top: -106px;
        margin-bottom: -106px;
    }

    &_img {
        width: 100%;
        height: 1027px;
        background-image: url(../../assets/images/parallax-img.png);
        background-size: cover;
        background-position: center;
        background-position-y: top;
        background-repeat: no-repeat;
        &.active {
            height: 902px;
        }
    }
}

.info {
    &_title {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 198px;
        font-family: $font-primary;
        font-style: normal;
        font-weight: 800;
        font-size: 80px;
        line-height: 100%;
        color: #1B7A59;
        margin: 0;
        transition: all $duration;

        &.active {
            top: -230px;
        }

        & span {
            color: #2073E3;
        }
    }

    &_text {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        top: 299px;
        font-family: $font-primary;
        font-style: normal;
        font-weight: 300;
        font-size: 40px;
        line-height: 70px;
        color: $color-black;
        margin: 0;
        letter-spacing: 0.2px;
        transition: all $duration;
        &.active {
            top: -127px;
        }
    }
}

.about {
    max-width: 830px;
    width: 80%;
    font-family: $font-primary;
    color: #FFFFFF;
    position: absolute;
    opacity: 0;
    left: 50%;
    top: 170px;
    transform: translate(-50%, 0);
    transition: all $duration;

    &.active {
        opacity: 1;
    }

    &_bg {
        background-image: url(../../assets/images/text_bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: 0;
        height: 1152px;
        width: 100%;
        position: absolute;
        bottom: 0;
        z-index: 2;
        transition: all $duration;
        &.active {
            background-image: url(../../assets/images/text_bg-after.png);
            height: 481px;
        }
    }

    &_title {
        font-style: normal;
        font-weight: 600;
        font-size: 56px;
        line-height: 110%;
        margin: 0;
        margin-block-end: 0;
        margin-bottom: 30px;
        letter-spacing: 0.6px;
    }

    &_text {
        margin: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 140%;
        letter-spacing: 0.3px;
    }
}

.trees {
    top: 386px;
    width: 100%;
    height: 556px;
    left: 50%;
    transform: translate(-50%) scale(-1, 1);
    position: absolute;
    transition: all $duration;
    display: flex;
    justify-content: space-between;

    &_l {
        width: 50%;
        height: 100%;
        background-image: url(../../assets/images/trees_l-before.png);
        background-size: 57% 100%;
        background-position-x: -27%;
        background-repeat: no-repeat;
    }

    &_r {
        width: 50%;
        height: 100%;
        background-image: url(../../assets/images/trees_r-before.png);
        background-size: 56% 100%;
        background-position-x: 131%;
        background-repeat: no-repeat;
    }

    &.active {
        top: 78px;
        height: 398px;
        width: 74%;
        & .trees_l {
            background-image: url(../../assets/images/trees_l-after.png);
            background-position-x: -20%;
        }

        & .trees_r {
            background-image: url(../../assets/images/trees_r-after.png);
            background-position-x: 124%;
        }
    }
}

.hill {
    position: absolute;
    top: 598px;
    width: 100%;
    height: 560px;
    background-image: url(../../assets/images/hill.png);
    transition: all $duration;

    &.active {
        top: 158px;
        height: 356px;
        background-image: url(../../assets/images/hill-after.png);
    }
}

.big_trees {
    position: absolute;
    top: 610px;
    width: 100%;
    height: 522px;
    display: flex;
    justify-content: space-between;
    z-index: 3;
    transition: all $duration;

    &_l {
        width: 50%;
        height: 100%;
        background-image: url(../../assets/images/trees_l-before.png);
        background-size: 62% 100%;
        background-position-x: -47%;
        background-repeat: no-repeat;
    }

    &_r {
        width: 50%;
        height: 100%;
        background-image: url(../../assets/images/trees_r-before.png);
        background-size: 56% 100%;
        background-position-x: 133%;
        background-repeat: no-repeat;
    }

    &.active {
        top: 66px;
        height: 535px;

        & .big_trees_l {
            background-image: url(../../assets/images/trees_l-after.png);
            background-position-x: -43%;
        }

        & .big_trees_r {
            background-image: url(../../assets/images/trees_r-after.png);
            background-position-x: 137%;
        }
    }
}

.dark_hill {
    position: absolute;
    top: 700px;
    width: 100%;
    height: 460px;
    background-image: url(../../assets/images/dark_hill.png);
    background-position-x: center;
    background-size: 101% auto;
    background-repeat: no-repeat;
    transition: all $duration;

    &.active {
        top: 226px;
        height: 302px;
        background-image: url(../../assets/images/dark_hill-after.png);
    }
}

.garbage {
    &_r {
        position: absolute;
        top: 759px;
        right: 136px;
        width: 370px;
        height: 223px;
        background-image: url(../../assets/images/garbage.png);
        background-repeat: no-repeat;
        background-size: contain;
        transition: all $duration;

        &.active {
            top: 588px;
            right: 0;
            width: 129px;
            height: 228px;
        }
    }

    &_l {
        position: absolute;
        width: 370px;
        height: 223px;
        top: 754px;
        left: 13px;
        background-image: url(../../assets/images/garbage.png);
        background-repeat: no-repeat;
        background-size: contain;
        transition: all $duration;

        &.active {
            width: 154px;
            height: 228px;
            left: 0;
            top: 575px;
        }
    }
}

.small_trees {
    position: absolute;
    top: 462px;
    left: 50%;
    width: 77.6%;
    height: 502px;
    transform: translateX(-49%);
    background-image: url(../../assets/images/small_trees.png);
    background-repeat: no-repeat;
    background-size: contain;
    transition: all $duration;

    &.active {
        background-image: url(../../assets/images/small_trees-after.png);
        top: 145px;
        width: 47.7%;
        height: 306px;
    }
}

@media (max-width: 1350px) {
    .dark_hill.active {
        top: 275px;
    }
    .small_trees.active {
        top: 170px;
    }
}

@media (max-width: 1200px) {
    .parallax {
        height: 1024px;

        &.active {
            height: 1024px;
        }
    }

    .parallax_img {
        height: 800px;

        &.active {
            height: 800px;
        }
    }

    .about_bg {
        height: 240px;

        &.active {
            height: 571px;
        }
    }

    .big_trees {
        height: 400px;
    }

    .dark_hill {
        top: 550px;
    }

    .garbage_l {
        top: 580px;
    }

    .garbage_r {
        top: 580px;
    }

    .dark_hill.active {
        top: 424px;
    }

    .hill.active {
        top: 250px;
    }

    .big_trees.active {
        top: 90px;
    }

    .trees.active {
        top: 180px;
    }

    .small_trees.active {
        top: 327px;
    }

    .about_title {
        font-size: 36px;
    }

    .about_text {
        font-weight: 500;
    }
}

@media (max-width: 1120px) {
    .info {
        &_title {
            font-size: 52px;
            top: 173px;
        }

        &_text {
            font-size: 24px;
            top: 206px;
        }
    }

    .about_bg {
        height: 270px;
    }

    .big_trees_l {
        background-size: 70% 100%;
    }

    .big_trees_r {
        background-size: 62% 100%;
    }

    .trees_l {
        background-size: 70% 100%;
    }

    .trees_r {
        background-size: 62% 100%;
    }

    .garbage_l,
    .garbage_r {
        width: 250px;
    }

    .garbage_l {
        left: 106px;
    }

    .garbage_r {
        right: 170px;
    }
}

@media (max-width: 950px) {
    .about_bg {
        height: 380px;
    }

    .big_trees {
        top: 500px;
    }

    .parallax_img {
        background-position-y: -132px;
    }

    .big_trees.active {
        top: 260px;
        height: 313px;

        .big_trees_l {
            background-position-x: 6%;
        }

        .big_trees_r {
            background-position-x: 100%;
        }
    }

    .trees.active {
        top: 264px;
        height: 238px;

        .trees_l {
            background-position-x: -20%;
        }

        .trees_r {
            background-position-x: 71%;
        }
    }

    .small_trees.active {
        top: 327px;
        height: 139px;
        background-position-x: center;
    }
}

@media (max-width: 800px) {
    .info {
        &_title {
            font-size: 28px;
            top: 173px;
        }

        &_text {
            font-size: 15px;
            line-height: 17px;
            top: 206px;
            white-space: nowrap;
        }
    }

    .big_trees {
        height: 313px;
        top: 391px;
    }

    .trees {
        height: 249px;
        top: 396px;
    }

    .garbage_l,
    .garbage_r {
        width: 127px;
        height: 77px;
    }

    .garbage_l {
        left: 106px;
        top: 601px;
    }

    .garbage_r {
        right: 135px;
        top: 596px;
    }

    .big_trees_l {
        background-size: 68% 100%;
    }

    .big_trees_r {
        background-size: 60% 100%;
    }

    .trees_l {
        background-size: 62% 100%;
    }

    .trees_r {
        background-size: 60% 100%;
    }
}

@media (max-width: 650px) {
    .dark_hill {
        top: 570px;
    }

    .about_title {
        font-size: 24px;
    }

    .about_text {
        font-size: 20px;
    }
}

@media (max-width: 600px) {
    .parallax {
        height: 800px;

        &.active {
            height: 800px;
        }
    }

    .about_bg {
        height: 155px;

        &.active {
            height: 385px;
        }
    }

    .big_trees_l {
        background-size: 76% 100%;
    }

    .big_trees_r {
        background-size: 68% 100%
    }

    .small_trees {
        width: 58%;
    }

    .about {
        width: 86.15%;
    }

    .about_title {
        font-size: 20px;
    }

    .about_text {
        font-size: 16px;
        font-weight: 400;
    }
}

@media (max-width: 530px) {
    .garbage_l {
        left: 55px;
        top: 610px;
    }

    .garbage_r {
        right: 53px;
        top: 571px;
    }

    .small_trees {
        height: 116px;
        top: 510px;
    }

    .dark_hill {
        top: 580px;
    }

    .about_bg.active {
        height: 490px;
    }

    .big_trees.active {
        height: 263px;
        top: 189px;
    }

    .trees.active {
        height: 191px;
        top: 157px;
        width: 100%;
        transform: translate(-50%) scale(-1, 1);

        & .trees_l {
            background-position-x: -44%;
            transform: translateY(25%);
        }

        & .trees_r {
            background-position-x: 120%;
        }
    }

    .dark_hill.active {
        height: 124px;
        top: 326px;
    }

    .small_trees.active {
        height: 116px;
        top: 290px;
    }
}

@media (max-width: 450px) {
    .big_trees_l {
        background-size: 82% 100%;
    }

    .big_trees_r {
        background-size: 76% 100%;
    }
}

@media (max-width: 400px) {
    .big_trees_l {
        background-size: 92% 100%;
        background-position-x: -375%;
    }

    .big_trees_r {
        background-size: 88% 100%;
        background-position-x: 375%;
    }

    .dark_hill {
        height: 250px;
        background-size: 101% 60%;
    }
}