@import '../../index.scss';

.navCard {
    max-width: 310px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 30px;
    row-gap: 25px;
    &:nth-child(odd) {
        flex-direction: row-reverse;
    }
    @media screen and (min-width: 400px) {
        max-width: fit-content;
    }
    @media screen and (min-width: 600px) {
        flex-direction: column;
        max-width: auto;
        row-gap: 30px;
        column-gap: calc(40 / 600 * 100vw);
        &:nth-child(odd) {
            flex-direction: column;
        }
    }
    @media screen and (min-width: 1200px) {
        flex-direction: row;
        align-items: flex-start;
        &:nth-child(odd) {
            flex-direction: row-reverse;
        }
        column-gap: calc(30 / 1440 * 100vw);
    }
    @media screen and (min-width: 1440px) {
        column-gap: 30px;
        max-width: 1170px;
    }
}

.navCardImage {
    max-width: 310px;
    height: 196px;
    img {
        width: 100%;
        height: 100%;
        border-radius: 30px;
    }
    @media screen and (min-width: 400px) {
        max-width: fit-content;
        height: auto;
    }
    @media screen and (min-width: 600px) {
        min-width: calc(570 / 768 * 100vw);
        height: calc(360 / 768 * 100vw);
    }
    @media screen and (min-width: 1200px) {
        min-width: calc(570 / 1440 * 100vw);
        height: calc(360 / 1440 * 100vw);
    }
    @media screen and (min-width: 1440px) {
        min-width: 570px;
        height: 360px;
    }
}

.navCardContent {
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 25px;
    @media screen and (min-width: 600px) {
        row-gap: 20px;
        min-width: calc(570 / 768 * 100vw);
        // padding-left: calc(50 / 768 * 100vw);
    }
    @media screen and (min-width: 1200px) {
        row-gap: 22px;
        min-width: auto;
        max-width: calc(570 / 1440 * 100vw);
    }
    @media screen and (min-width: 1440px) {
        max-width: 570px;
    }
}

.navCardHeading {
    margin: 0;
    letter-spacing: 0.4px;
    @media screen and (min-width: 600px) {
        font-weight: 600;
        font-size: 36px;
        color: $color-black;
        line-height: 40px;
    }
    @media screen and (min-width: 1200px) {
        line-height: 110%;
        font-size: calc(48 / 1440 * 100vw);
    }
    @media screen and (min-width: 1440px) {
        font-size: 48px;
    }
}

.navCardText {
    display: flex;
    flex-direction: column;
    row-gap: 13.6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: $color-black;
    letter-spacing: 0.1px;
    @media screen and (min-width: 600px) {
        row-gap: 16px;
        // letter-spacing: 0.1px;
        font-size: 20px;
        line-height: 28px;
    }
    @media screen and (min-width: 1200px) {
        row-gap: 20px;
        font-size: calc(20 / 1440 * 100vw);
        line-height: calc(28 / 1440 * 100vw);
    }
    @media screen and (min-width: 1440px) {
        font-size: 20px;
        line-height: 28px;
    }
    p {
        margin: 0;
    }
}

.navCard:nth-child(2) .navCardText {
    row-gap: 11.2px;
    @media screen and (min-width: 600px) {
        row-gap: 16px;
    }
    @media screen and (min-width: 1200px) {
        row-gap: 20px;
    }
}

.navCardButton {
    // height: 24px;
    // @media screen and (min-width: 600px) {
    //     height: 40px;
    // }
    // @media screen and (min-width: 1200px) {
    //     height: 25.2px;
    // }
    a {
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        padding: 8px 15px 8px 0;
        border-radius: 35px;
        border: 2px solid transparent;
        outline: none;

        @media screen and (min-width: 600px) {
            padding: 15px 15px 15px 20px;
            margin-left: -20px;
        }

        &:hover {
            background: #E3EFDD;
        }
        &:active {
            background: #D4E5D4;
        }
        &:focus {
            border: 2px solid #1B7A59;
        }
    }

    &_disabled {
        a{
            span {
                color: #7C7C7C;
            }
            &:hover {
                background: none;
            }
            &:active {
                background: none;
            }
            &:focus {
                border: 2px solid transparent;
            }
        }
    }

    span {
        letter-spacing: 0.2px;
        color: #1B7A59;
        line-height: 110%;
        font-weight: 600;
        font-size: 16px;
        margin-right: 5px;
        @media screen and (min-width: 600px) {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            margin-right: 5px;
        }
        @media screen and (min-width: 1200px) {
            font-size: 24px;
            line-height: 110%;
            margin-right: 20px;
        }
    }
    img {
        width: 24px;
        height: 24px;
        // @media screen and (min-width: 600px) {
        //     width: 40px;
        //     height: 40px;
        // }
        @media screen and (min-width: 1200px) {
            width: 12.72px;
            height: 25.03px;
        }
    }
}

.navCardLink {
    display: inline-flex;
    color: #070707;
    font-family: $font-primary;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    border-bottom: 1px solid transparent;
    cursor: pointer;
    
    &:hover {
        border-color: #000000;
    }
}