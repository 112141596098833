@import './index.scss';

* {
    transition: all .3s;
}

html {
    height: 100%;
    scroll-behavior: smooth;
}

body {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: $color-black;
}

h2 {
    display: inline-block;
    font-family: $font-primary;
    font-weight: 600;
    color: $color-black;
    font-size: 28px;
    line-height: 105%;
    @media screen and (min-width: 768px) {
        font-size: 36px;
    }
    @media screen and (min-width: 1200px) {
        font-size: 48px;
    }
}

.button {
    display: inline-block;
    text-decoration: none;
    border: 0;
    cursor: pointer;
    border-radius: $button-radius;
    text-align: center;
    font-family: $font-secondary;
    font-size: 30px;
    line-height: 33px;
    background-color: $color-primary;
    color: $color-black;
    transition: all .3s ease-out;
    transition-delay: 0.3s;
    &:hover {
        background-color: $color-light;
        color: $color-black;
        transition-delay: 0ms;
    }
    &:active {
        background-color: $color-dark;
        color: $color-white;
        transition-delay: 0ms;
    }
}

.secondary-btn {
    min-width: 270px;
    padding: 17px 30px;
    border-radius: 35px;
    border: 2px solid #2073E5;
    color: #2073E5;
    text-align: center;
    font-family: $font-primary;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    cursor: pointer;
    user-select: none;
    
    &:hover {
        background: #E8F1FC;
    }

    &:active {
        background: #D2E3FA;
    }

    &:disabled {
        border: 2px solid #7C7C7C;
        color: #7C7C7C;
    }
}

//to correct leaflet map z-index
//
//.leaflet-pane {
//    z-index: auto;
//}
//.leaflet-control {
//    z-index: auto;
//}
//.leaflet-top,
//.leaflet-bottom {
//    z-index: auto;
//}
//.leaflet-pane,
//.leaflet-map-pane
//{
//    transform: translate3d(0px, 0px, 0px) !important;
//}
//.leaflet-control {
//    z-index: auto;
//}
//.leaflet-top,
//.leaflet-bottom {
//    z-index: auto;
////}


.leaflet-popup-content {
    margin: 0px;

}

.leaflet-popup-content-wrapper {
    padding: 0px;
    border: 0px;
    border-radius: 20px;

}
.leaflet-popup-tip {
    background:#CAE3F9;
}

.leaflet-attribution-flag {
    display: none !important;
}

.marquee-container {
  height: 70px;
}

.App {
    display: block;
    max-width: 1440px;
    margin: 0 auto;
}

@media (max-width: 1100px) {
    .secondary-btn {
        font-size: 20px;
        padding: 10px 15px ;
    }
}

@media (max-width: 600px) {
  .marquee-container {
    height: 50px;
  }
}
