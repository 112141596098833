// font-family: 'Neucha', cursive;
@import url('https://fonts.googleapis.com/css2?family=Neucha&display=swap');


// font-family: 'Rising Sun', sans-serif;
@font-face {
    font-family: 'Rising Sun';
    font-style: normal;
    font-weight: 300;
    src: local('Rising Sun Light'), url('../assets/fonts/RisingSun-Light.woff') format('woff');
}

@font-face {
    font-family: 'Rising Sun';
    font-style: italic;
    font-weight: 300;
    src: local('Rising Sun Light Italic'), url('../assets/fonts/RisingSun-LightItalic.woff') format('woff');
}

@font-face {
    font-family: 'Rising Sun';
    font-style: normal;
    font-weight: 400;
    src: local('Rising Sun Regular'), url('../assets/fonts/RisingSun-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Rising Sun';
    font-style: italic;
    font-weight: 400;
    src: local('Rising Sun Italic'), url('../assets/fonts/RisingSun-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Rising Sun';
    font-style: normal;
    font-weight: 500;
    src: local('Rising Sun Medium'), url('../assets/fonts/RisingSun-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Rising Sun';
    font-style: italic;
    font-weight: 500;
    src: local('Rising Sun Medium Italic'), url('../assets/fonts/RisingSun-MediumItalic.ttf') format('truetype');
}
    
@font-face {
    font-family: 'Rising Sun';
    font-style: normal;
    font-weight: 600;
    src: local('Rising Sun DemiBold'), url('../assets/fonts/RisingSun-DemiBold.woff') format('woff');
}

@font-face {
    font-family: 'Rising Sun';
    font-style: italic;
    font-weight: 600;
    src: local('Rising Sun DemiBold Italic'), url('../assets/fonts/RisingSun-DemiBoldItalic.woff') format('woff');
}

@font-face {
    font-family: 'Rising Sun';
    font-style: normal;
    font-weight: 700;
    src: local('Rising Sun Bold'), url('../assets/fonts/RisingSun-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Rising Sun';
    font-style: italic;
    font-weight: 700;
    src: local('Rising Sun Bold Italic'), url('../assets/fonts/RisingSun-BoldItalic.woff') format('woff');
}

@font-face {
    font-family: 'Rising Sun';
    font-style: normal;
    font-weight: 800;
    src: local('Rising Sun Heavy'), url('../assets/fonts/RisingSun-Heavy.woff') format('woff');
}

@font-face {
    font-family: 'Rising Sun';
    font-style: italic;
    font-weight: 800;
    src: local('Rising Sun Heavy Italic'), url('../assets/fonts/RisingSun-HeavyItalic.woff') format('woff');
}

@font-face {
    font-family: 'Rising Sun';
    font-style: normal;
    font-weight: 900;
    src: local('Rising Sun Black'), url('../assets/fonts/RisingSun-Black.woff') format('woff');
}

@font-face {
    font-family: 'Rising Sun';
    font-style: italic;
    font-weight: 900;
    src: local('Rising Sun Black Italic'), url('../assets/fonts/RisingSun-BlackItalic.woff') format('woff');
}

// font-family: 'Roboto', sans-serif;
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto Regular'), url('../assets/fonts/Roboto-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local('Roboto Medium'), url('../assets/fonts/Roboto-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local('Roboto Bold'), url('../assets/fonts/Roboto-Bold.woff') format('woff');
}
