@import '../../index.scss';

.recycleWrapper {
    padding: 30px 25px;
    background: #CAE3F9;
    @media screen and (min-width: 768px) {
        padding: 45px 39px;
    }
    @media screen and (min-width: 1200px) {
        padding: 50px 135px;
    }
}

.recycleHeading {
    display: inline-flex;
    margin: 0 0 25px;
    line-height: 31px;
    @media screen and (min-width: 768px) {
        margin: 0 0 30px;
        line-height: 40px;
    }
    @media screen and (min-width: 1200px) {
        margin: 0 0 50px;
    }
    letter-spacing: 0.4px;
}

.recycleInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 25px;
    @media screen and (min-width: 768px) {
        row-gap: 20px;
    }
    @media screen and (min-width: 1200px) {
        row-gap: 30px;
    }
}
