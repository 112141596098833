@import '../../index.scss';

.mapContainer {
  width: 100%;
  height: 543px;
}

.mapHeaderWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: $color-white;
  padding: 25px 25px 0px 25px;
}

.mapWrapper {
  background: $color-white;
  padding: 0px;
}


.buttonsWrapper {
  width: 100%;
  padding: 0px;
}


//input.searchField {
//  font-size: 24px;
//  width: 100%;
//  height: 40px;
//  margin-bottom: 20px;
//  background-image: url('../../assets/images/search.svg');
//  background-repeat:no-repeat;
//  background-position:left center;
//  padding-left:40px;
//  background-color: $color-white;
//  border: 1px solid $color-black;
//  border-top-style: hidden;
//  border-right-style: hidden;
//  border-left-style: hidden;
//
//}
//
//input.searchField:focus {
//  outline: none;
//}
//
//::placeholder {
//  color: #A9A9A9;
//  opacity: 1;
//}


.popup {
  width: 243px;
}


.popupHeader {
  background: $color-primary;
  font-family: 'Rising Sun';
  font-size: 10px;
  line-height: 140%;
  padding: 10px 15px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  color: $color-black;
  border-radius: 20px 20px 0px 0px;
}

.popupAddressWrapper {
  background: $color-white;
  margin: 0px;
  font-family: 'Rising Sun';
  font-size: 10px;
  line-height: 140%;
  padding: 2px 15px;
  font-style: normal;
  font-weight: 500;
  color: $color-black;
}


.popupFooter {
  background: #CAE3F9;
  font-family: 'Rising Sun';
  font-size: 10px;
  line-height: 140%;
  padding: 10px 15px;
  font-style: normal;
  font-weight: 400;
  color: $color-black;
  border-radius: 0px 0px 20px 20px;
}


ul.wasteTypes {
  list-style-type: none;
  font-family: 'Rising Sun';
  font-size: 10px;
  line-height: 140%;
  font-style: normal;
  font-weight: 500;
  color: $color-black;
  padding: 0px;
  margin: 5px 0px;
}

ul.wasteTypes li::first-letter {
  text-transform: capitalize;
}


ul.address {
  padding: 0px 10px 0px 15px;
}

.locationPoint {
  list-style-image: url(../../assets/images/u_location-point_10.svg);
}

.phone {
  list-style-image: url(../../assets/images/u_phone_10.svg);
}

.schedule {
  list-style-image: url(../../assets/images/u_clock-three_10.svg);
}

.website {
  list-style-image: url(../../assets/images/u_link_10.svg);
}

.popupAddressContent {
  position: relative;
  top: -1px;
}


@media only screen and (max-width: 767px) {


  .mapHeaderWrapper {
    padding: 5px 25px 0px 25px;
  }

  .buttonsWrapper {
    margin-bottom: 5px;
  }

}


@media only screen and (min-width: 768px) {

  .mapHeaderWrapper {
    padding: 5px 39px 0px 39px;
  }

  .mapWrapper {
    padding: 15px 39px 45px 39px;
  }

  .buttonsWrapper {
    margin-bottom: 10px;
  }


}


@media only screen and (min-width: 1200px) {

  .mapHeaderWrapper {
    padding: 10px 135px;
  }


  .buttonsWrapper {
    padding: 10px 0px 5px 0px;
    margin-bottom: 0px;
  }


  .mapWrapper {
    padding: 0px 135px 50px 135px;
  }


  .mapContainer {
    padding: 38px 135px 55px;
  }

  .popup {
    width: 370px;
  }

  .popupHeader {
    padding: 27px 13px 10px 10px;
    font-size: 18px;
    line-height: 25px;

  }

  .popupAddressWrapper {
    padding: 1px 1px 1px 10px;
    margin: 0px;
    font-size: 14px;
    line-height: 22px;
  }

  ul.address {
    padding: 0px 0px 0px 30px;
  }


  .locationPoint {
    list-style-image: url(../../assets/images/u_location-point_24.svg);
  }
  .phone {
    list-style-image: url(../../assets/images/u_phone_24.svg);
  }
  .schedule {
    list-style-image: url(../../assets/images/u_clock-three_24.svg);
  }
  .website {
    list-style-image: url(../../assets/images/u_link_24.svg);
  }
  .popupAddressContent {
    position: relative;
    top: -7px;
  }

  .popupFooter {
    padding: 10px 10px 5px 10px;
    font-size: 18px;
    line-height: 25px;
  }

  ul.wasteTypes {
    font-size: 16px;
    line-height: 22px;
    padding: 0px;
    margin: 5px 0px;
  }


}