// colors
$color-primary: #94B9AB;
$color-secondary: #0C6DCE;
$color-light: #BDD9CE;
$color-bright: #DDC8FF;
$color-black: #070707;
$color-dark: #0C3626;
$color-white: #F6FCEA;
$color-grey: #707070;


$color-button: #2196F3;
$color-error: #D32F2F;
$color-global-white: #FFFFFF;

$color-primary-form: #2073E3;
$color-primary-form-hover: #4895FF;
$color-primary-form-active: #1A5FBE;

$color-swiper-bullet: #1b7a59;

// fonts
$font-primary: 'Rising Sun', san-serif;
$font-secondary: 'Neucha', cursive;
$font-admin: 'Roboto', san-serif;

// button
$button-radius: 10px;

// nav-button
$nav-button-shadow: 0px 44px 18px rgba(56, 67, 62, 0.01), 0px 25px 15px rgba(56, 67, 62, 0.05), 0px 11px 11px rgba(56, 67, 62, 0.09), 0px 3px 6px rgba(56, 67, 62, 0.1), 0px 0px 0px rgba(56, 67, 62, 0.1);

// cards
$vert-big-shadow: 0px 108px 43px rgba(19, 0, 51, 0.01), 0px 61px 36px rgba(19, 0, 51, 0.05), 0px 27px 27px rgba(19, 0, 51, 0.09), 0px 7px 15px rgba(19, 0, 51, 0.1), 0px 0px 0px rgba(19, 0, 51, 0.1);
$vert-small-shadow: 0px 87px 35px rgba(45, 1, 121, 0.01), 0px 49px 29px rgba(45, 1, 121, 0.05), 0px 22px 22px rgba(45, 1, 121, 0.09), 0px 5px 12px rgba(45, 1, 121, 0.1), 0px 0px 0px rgba(45, 1, 121, 0.1);
$hori-big-shadow: 0px 85px 34px rgba(19, 0, 51, 0.01), 0px 48px 29px rgba(19, 0, 51, 0.05), 0px 21px 21px rgba(19, 0, 51, 0.09), 0px 5px 12px rgba(19, 0, 51, 0.1), 0px 0px 0px rgba(19, 0, 51, 0.1);
$hori-small-shadow: 0px 75px 30px rgba(19, 0, 51, 0.01), 0px 42px 25px rgba(19, 0, 51, 0.05), 0px 19px 19px rgba(19, 0, 51, 0.09), 0px 5px 10px rgba(19, 0, 51, 0.1), 0px 0px 0px rgba(19, 0, 51, 0.1);
