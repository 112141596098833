.backScreen {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 1002;
  background-color: rgba(7, 7, 7, 0.60);
}

.popUp {
  display: flex;
  flex-direction: column;
  background-color: #F6FCEA;
  border-radius: 30px;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  @media screen and (min-width: 640px) {
    width: auto;
    border-radius: 30px;
    max-height: 95vh;
  }
  &.noBorderRadiusMobile {
    border-radius: 0;
    @media screen and (min-width: 640px) {
      border-radius: 30px;
    }
  }
}

.popUp::-webkit-scrollbar {
  display: none;
}

.popUpWrapper {
  display: flex;
  flex-direction: column;
  padding: 15px 25px 28px;
  height: 100%;
  @media screen and (min-width: 500px) {
    padding: 20px 35px;
  }
  &.largePadding {
    padding: 25px;
    @media screen and (min-width: 500px) {
      padding: 35px;
    }
    @media screen and (min-width: 1200px) {
      padding: 50px;
    }
  }
}

.closeBlock {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  padding: 0;
  margin-bottom: 5px;
}

.closeButton {
  background-color: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  @media screen and (min-width: 500px) {
    width: 40px;
    height: 40px;
  }
}

.closeButton img {
  width: 100%;
  height: 100%;
}
