@import '../../index.scss';

.navigation {
    display: flex;
    gap: 50px;
}

.navigation_link {
    color: $color-black;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    font-style: normal;
    font-weight: 400;
    font-family: $font-primary;
    font-size: 24px;
    line-height: 105%;
    text-wrap: nowrap;
    letter-spacing: 0.3px;
    width: fit-content;
    cursor: pointer;
}

.navigation_link:hover {
    border-color: #000000;
}

.disabled {
    pointer-events: none;
    border-color: #000000;
}

@media (max-width: 1250px) {
    .navigation {
        gap: 44px;
    }

    .navigation_link {
        font-size: 20px;
        line-height: 140%;
    }
}

@media (max-width: 680px) {
    .navigation_link {
        font-size: 24px;
        line-height: 105%;
    }

    .footer .navigation_link {
        font-size: 20px;
        line-height: 105%;
    }
}