@import '../../../index.scss';

.createPoint {
  padding: 145px 0 115px;

  @media (max-width: 1439px){
    padding: 115px 0 80px
  }

  @media (max-width: 767px){
    padding: 105px 0 50px;
  }
}

.createPoint__container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 108px;

  @media (max-width: 1439px){
    padding: 0 40px;
  }

  @media (max-width: 767px){
    padding: 0 10px;
  }
}

.createPoint__title {
  margin: 0;
  margin-bottom: 40px;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  color: $color-black;

  @media (max-width: 1439px) {
    font-size: 36px;
    line-height: 110%;
  }

  @media (max-width: 767px){
    font-size: 28px;
    margin-bottom: 30px;
  }
}

.createPoint__textareaBox {
  position: relative;
  margin-bottom: 24px;
  max-width: 600px;
}

.createPoint__textareaLabel {
  padding: 0 5px;
  color: rgba($color-black, 0.6);
  position: absolute;
  background-color: $color-global-white;
  top: -8px;
  left: 15px;

  &_error {
    color: $color-error;
  }
}

.createPoint__textarea {
  box-sizing: border-box;
  padding: 16px 12px;
  width: 100%;
  min-height: 153px;
  border-radius: 4px;
  border: 1px solid rgba($color-black, 0.23);
  resize: none;

  &_error {
    border: 1px solid $color-error;
  }
}

.createPoint__textareaCount {
  color: rgba($color-black, 0.6);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
  margin: 3px 0 0 14px;
}

.createPoint__subtitle {
  margin: 0;
  margin-bottom: 17px;
  color: rgba($color-black, 0.87);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;

  &_error {
    color: $color-error;
  }
}

.createPoint__checkboxes {
  max-width: 600px;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;

  & label {
    @media (max-width: 767px) {
      align-items: flex-start;
    }

    & span {
      @media (max-width: 767px) {
        padding-top: 0;
        padding-bottom: 10px;
      }
    }
  }
}

.createPoint__buttons {
  margin-top: 48px;
}
