.stickyContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: sticky;
  z-index: 1001;
  bottom: 15px;
  right: 0px;
  padding-right: 15px;
  @media screen and (min-width: 600px) {
    bottom: 30px;
    padding-right: 20px;
  }
  @media screen and (min-width: 1200px) {
    bottom: 30px;
    padding-right: 70px;
  }
}

.contactButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2073E5;
  border-radius: 50%;
  cursor: pointer;
  width: 45px;
  height: 45px;
  img {
    width: 24px;
    height: 24px;
  }
  @media screen and (min-width: 600px) {
    width: 80px;
    height: 80px;
    img {
      width: 40px;
      height: 40px;
    }
  }
  @media screen and (min-width: 1200px) {
    width: 90px;
    height: 90px;
    img {
      width: 50px;
      height: 50px;
    }
  }
}
