@import '../../index.scss';

.header {
    position: sticky;
    top: -106px;
    z-index: 1001;
    height: 106px;
    background: $color-white;
    display: flex;

    & .container {
        margin: 0 auto;
        width: 1194px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px;
        position: relative;

        &.opened-menu {
            width: 100vw;
            height: 100%;
            position: absolute;
        }
    }

    &.visible {
        top: 0;
    }

    &.fixed {
        top: 0;
    }

    &-mobile-burger {
        width: 40px;
        height: 26px;
        position: relative;
        display: none;
        flex-direction: column;
        align-items: center;
        align-self: center;
        padding: 5px;
        &.cross {
            display: block;
            z-index: 1003;
        }
    }

    &-mobile-menu {
        position: absolute;
        right: -100%;
        top: 0;
        max-width: 350px;
        width: 100%;
        height: 100%;
        background: #f6fcea;
        display: none;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        z-index: 1002;
        transition: right 0.5s;
        &.opened {
            right: 0;
        }
    }
    .search-form {
        border-color: transparent;
    }
    .search-btn {
        display: block;
    }
}

.body-plug {
    height: 100%;
    background: transparent;
    transition: background 0.3s;

    &.opened {
        background: rgba(0, 0, 0, 0.2);
        width: 100vw;
        height: 100vh;
    }
}

.logo {
    position: relative;
    z-index: 1003;
    display: block;
    left: 0;
    width: 66px;
    height: 70px;
    background-image: url(../../assets/images/Logo.png);
    background-size: contain;
    background-repeat: no-repeat;
}

.burger-line {
    position: absolute;
    width: 30px;
    height: 2px;
    transition: all 0.3s ease;
    background: #070707;
    border-radius: 2px;

    &:first-child {
        top: calc(50% - 8px);
    }

    &:nth-child(2) {
        top: 50%;
    }

    &:last-child {
        width: 50%;
        align-self: flex-end;
        top: calc(50% + 8px);
    }
}

.cross {
    padding-top: 8px;

    & .burger-line:nth-child(2) {
        opacity: 0;
    }

    & .burger-line:first-child {
        top: 47%;
        left: 10%;
        transform: rotate(45deg);
    }

    & .burger-line:last-child {
        width: 30px;
        top: 50%;
        left: 10%;
        transform: rotate(-45deg);
    }
}

.headrer-menu-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 105%;
    color: #070707;
    margin-bottom: 150px;
}

.plug {
    width: 0px;
    height: 0px;
    background-color: transparent;
    position: absolute;
    right: 39px;
    top: 20px;
    z-index: 1003;
    cursor: pointer;
}

.navigation-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 70px;
    position: relative;
    margin-right: 25px;
}
.search-plug {
    height: 40px;
    width: 40px;
    position: relative;
}
.search-form {
    position: absolute;
    right: -26px;
    top: -11px;
    min-height: 60px;
    width: 90px;
    padding: 10px 25px;
    border-radius: 35px;
    border: 1px solid #070707;
    background: #f6fcea;
    font-family: $font-primary;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: 0.3px;
    color: $color-black;
    opacity: 1;
    transition: auto;
    transition-timing-function: ease-in;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 3;
    transition: all 0.5s;

    & .search-input {
        width: 0;
        border: none;
        outline: none;
        background: transparent;
        transition: all 0.5s;
        height: 26px;

        &::placeholder {
            color: rgba(7, 7, 7, 0.4);
        }
    }

    & .underline {
        width: 92%;
        height: 0;
        margin: 0 auto;
        background-color: rgba(7, 7, 7, 0.6);
    }

    &.opened {
        transition: all 0.5s;
        border-color: #070707;
        width: 105%;
        opacity: 1;
        & .search-input {
            width: 100%;
        }

        &.showes {
            padding: 16px 0;
            padding-bottom: 0;
            border-color: #f6fcea;
            border-radius: 35px;
            background-color: $color-white;
            box-shadow:
                0px 25px 35px 0px rgba(0, 0, 0, 0.06),
                0px 0px 5px 0px rgba(0, 0, 0, 0.1);

            & .search-input {
                padding: 0 24px;
            }

            & .underline {
                height: 1px;
                margin-top: 16px;
            }

            & .res-list {
                margin-top: 25px;
                margin-bottom: 30px;
            }
        }
    }
}

.res-list {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0;
}

.res-item {
    height: 36px;
    margin: 0;
    padding: 0 24px;
    display: flex;
    align-items: center;
    color: inherit;

    &:hover {
        cursor: pointer;
        background: #e3efdd;
    }
}

.search-btn {
    display: none;
    width: 40px;
    height: 40px;
    position: absolute;
    opacity: 1;
    top: 10px;
    right: 25px;
    background-image: url(../../assets/icons/search.svg);
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    z-index: 4;
    transition: all 0.2s;

    &.opened {
        pointer-events: none;
        opacity: 0;
        z-index: 0;
    }
}
.close-search {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 24px;
    top: 10px;
    background-image: url(../../assets/icons/cross.svg);
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: all 0.2s;
    opacity: 1;
    &.hidden {
        opacity: 0;
        visibility: hidden;
    }
}

.logUp-btn {
    color: #070707;
    font-family: $font-primary;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    border-bottom: 1px solid transparent;
    cursor: pointer;

    &:hover {
        border-color: #000000;
    }
}

@media (max-width: 1250px) {
    .logUp-btn {
        font-size: 20px;
        line-height: 140%;
    }
}

@media (max-width: 1200px) {
    .header {
        & .container {
            padding: 15px 39px;
        }
    }
}

@media (max-width: 880px) {
    .header {
        height: 80px;
        top: -80px;

        & .container {
            gap: 15px;
        }

        & .navigation {
            display: none;
        }

        & .logUp-btn.desk {
            display: none;
        }

        &-mobile-burger {
            display: flex;
        }

        & .header-mobile-menu {
            display: block;
        }

        & .header-mobile-menu {
            display: flex;
            position: fixed;
            width: 350px;
            border-radius: 30px 0px 0px 30px;

            &-inner {
                display: flex;
                flex-direction: column;
                gap: 50px;
            }

            & .navigation {
                display: flex;
                flex-direction: column;
                margin-top: 177px;
                gap: 50px;
            }
        }

        & .navigation-wrapper {
            width: calc(100% - 93px);
            justify-content: flex-end;
        }
    }

    .logo {
        width: 48px;
        height: 50px;
    }

    .plug {
        width: 40px;
        height: 40px;
    }

    .search-form {
        font-size: 20px;

        &.opened {
            width: 82%;
        }
    }

    .search-form.opened.showes .res-list {
        margin-top: 20px;
        margin-bottom: 22px;
    }

    .res-list {
        gap: 3px;
    }
}

@media (max-width: 680px) {
    .header {
        & .container {
            gap: 0;
            justify-content: space-between;
        }
    }

    .logo {
        width: 43px;
        height: 50px;
    }

    .search-form {
        min-height: 50px;
        padding: 10px 15px;
        font-size: 16px;
        line-height: 140%;
        top: -13px;

        &.opened {
            right: -66px;
            width: calc(100vw - 70px);
            z-index: 1004;
        }
    }

    .search-plug {
        height: 24px;
        width: 24px;
        position: relative;
    }
    .search-btn {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 12px;

        &.opened {
            // transition: all 0.5s;
            right: -5vw;
            z-index: 1005;
        }
    }

    .res-item {
        height: 26px;
    }

    .close-search {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 20px;
        top: 12px;
    }
}

@media (max-width: 500px) {
    .header {
        & .container {
            padding: 15px 25px;
        }

        & .header-mobile-menu {
            width: 100%;
            max-width: 100%;
            border-radius: 0;
        }

        &-mobile-burger {
            width: 24px;
            height: 12px;
            padding: 2px;
        }
    }

    .search-form {
        &.opened {
            right: -52px;
            width: calc(100vw - 40px);
        }
    }

    .burger-line {
        width: 20px;

        &:first-child {
            top: calc(50% - 6px);
        }

        &:nth-child(2) {
            top: calc(50% - 1px);
        }

        &:last-child {
            top: calc(50% + 4px);
        }
    }

    .cross .burger-line:last-child {
        width: 20px;
    }

    .plug {
        width: 24px;
        height: 24px;
        top: 28px;
        right: 25px;
    }

    .search-btn.opened {
        right: -5vw;
    }
}

@media (max-width: 400px) {
    .search-btn.opened {
        right: -10vw;
    }
}
