@import '../../index.scss';

.page404 {
  height: calc(100vh - 106px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('../../assets/images/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;

  @media (max-width: 767px){
    height: calc(100vh - 80px);
  }
}

.page404__image {
  padding: 0;
  margin: 0;
  margin-top: 130px;
  margin-bottom: 30px;
  max-width: 496px;

  @media (max-width: 1439px){
    margin-top: 20px;
    max-width: 276px;
  }

  @media (max-width: 767px){
    margin-top: 45px;
    max-width: 186px;
  }
}

.page404__text {
  padding: 0;
  margin: 0;
  margin-bottom: 25px;
  color: $color-black;
  text-align: center;
  font-family: 'Rising Sun';
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;

  @media (max-width: 1439px){
    width: 350px;
    font-size: 24px;
    line-height: 140%;
  }

  @media (max-width: 767px){
    max-width: 205px;
    font-size: 18px;
  }
}

.page404__link {
  color: $color-primary-form;
  font-family: 'Rising Sun';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;

  @media (max-width: 1439px){
    font-size: 18px;
    font-weight: 400;
  }

  @media (max-width: 767px){
    max-width: 205px;
    font-size: 16px;
    font-weight: 600;
    line-height: 110%;
  }
}
