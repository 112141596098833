@import '../../../index.scss';

.logIn {
  max-width: 600px;
  padding: 0 15px;
  margin: 170px auto 0;
  margin-bottom: 43px;
  font-family: $font-admin;

  @media (max-width: 767px){
    margin: 80px auto 0;
  }

}

.logIn__title {
  padding-bottom: 10px;
  margin-top: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid rgba($color-black, 0.12);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 133%
}

.logIn__input {
  width: 100%;
  margin-bottom: 40px;
}

.logIn__text {
  color: $color-black;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 5px;
}

.logIn__link {
  color: $color-button;
  text-decoration: none;
}

Button.logIn__button {
  background-color: $color-button;
  width: 100%;
}
