.pointsMap__mapContainer {
  width: 100%;
  height: 480px;

  @media (max-width: 767px){
    height: 300px;
  }
}

.pointsMap__filters {
  display: flex;
  flex-wrap: wrap;
  margin-top: -8px;
  margin-bottom: 16px;
}

.pointsMap__chip {
  border: 1px solid #0288D1 !important;
  margin-right: 8px !important;
  margin-top: 8px !important;
  // height: 26px !important;

  &:last-child {
    margin-right: 0 !important;
  }
}
