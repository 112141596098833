@import '../../index.scss';

.text {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 70px;
    height: 70px;
    text-transform: uppercase;
    letter-spacing: 0em;
    color: #000000;
    margin-right: 20px;
}


@media (max-width: 600px) {
    .ticker {
        height: 50px;
    }

    .text {
        font-size: 18px;
        line-height: 50px;
        height: 50px;

    }
}
