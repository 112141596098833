@import 'src/index.scss';

.recycleCardButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #1B7A59;;
  cursor: pointer;
  padding: 0;
  border: 0;
  min-width: 40px;
  min-height: 40px;
  svg {
    width: 24px;
    height: 24px;
  }
  @media screen and (min-width: 768px) {
    min-width: 60px;
    min-height: 60px;
    svg {
      width: 40px;
      height: 40px;
    }
  }
}
