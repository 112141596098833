@import '../../../index.scss';

.pointForm {
  margin-bottom: 30px;
}

.pointForm__textareaBox {
  position: relative;
  margin-bottom: 24px;
  max-width: 600px;
}

.pointForm__textareaLabel {
  padding: 0 5px;
  color: rgba($color-black, 0.6);
  position: absolute;
  background-color: $color-global-white;
  top: -8px;
  left: 15px;

  &_error {
    color: $color-error;
  }
}

.pointForm__textarea {
  box-sizing: border-box;
  display: block;
  padding: 16px 12px;
  width: 100%;
  min-height: 153px;
  border-radius: 4px;
  border: 1px solid rgba($color-black, 0.23);
  resize: none;
  outline: none;
  transition: none;

  &:focus {
    min-height: 151px;
    padding: 15px 11px;
    border: 2px solid $color-primary-form;
  }

  &_error {
    border: 1px solid $color-error;
  }
}

.pointForm__textareaCount {
  color: rgba($color-black, 0.6);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
  margin: 3px 0 0 14px;
}

.pointForm__subtitle {
  margin: 0;
  margin-bottom: 17px;
  color: rgba($color-black, 0.87);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;

  &_error {
    color: $color-error;
  }
}

.pointForm__checkboxes {
  max-width: 600px;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;

  & label {
    @media (max-width: 767px) {
      align-items: flex-start;
    }

    & span {
      @media (max-width: 767px) {
        padding-top: 0;
        padding-bottom: 10px;
      }
    }
  }
}

.pointForm__buttons {
  margin-top: 48px;
}
