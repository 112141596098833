@import '../../index.scss';

.messageWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  @media screen and (min-width: 600px) {
    row-gap: 25px;
  }
  @media screen and (min-width: 1200px) {
    row-gap: 30px;
  }
}

.message {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 260px;
  row-gap: 10px;
  @media screen and (min-width: 600px) {
    max-width: 380px;
    row-gap: 20px;
  }
  @media screen and (min-width: 1200px) {
    max-width: 450px;
  }
}

.messageSmile {
  width: 60px;
  height: 60px;
  & img {
    width: 100%;
    height: 100%;
  }
}

.messageHeading {
  margin: 0;
  padding: 0;
  font-family: $font-primary;
  font-size: 28px;
  font-weight: 600;
  // line-height: 110%;
  line-height: 31px;
  color: $color-black;
  @media screen and (min-width: 600px) {
    font-weight: 600;
    font-size: 30px;
    line-height: 33px;
  }
  @media screen and (min-width: 1200px) {
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
  }
}

.messageText {
  font-family: $font-primary;
  font-size: 16px;
  // line-height: 140%;
  line-height: 22px;
  font-weight: 400;
  color: $color-black;
  text-align: center;
  margin: 0;
  @media screen and (min-width: 1200px) {
    font-size: 20px;
    // line-height: 140%;
    line-height: 28px;
  }
}

.email {
  font-weight: 400;
  @media screen and (min-width: 1200px) {
    font-weight: 500;
  }
}

.actionButtons {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.actionButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border-radius: 35px;
  font-family: $font-primary;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 11px 30px;
  max-height: 40px;
  min-width: 210px;
  font-size: 16px;
  font-weight: 600;
  line-height: 110%;
  @media screen and (min-width: 600px) {
    max-height: 50px;
    min-width: 270px;
    font-size: 20px;
    font-weight: 600;
    line-height: 140%;
  }
  @media screen and (min-width: 1200px) {
    padding: 17px 30px;
    min-width: 270px;
    font-size: 24px;
    line-height: 110%;
    max-height: 60px;
  }
}

.primaryButton {
  color: #fff;
  background-color: #1B7A59;
  min-height: 40px;
  &:hover {
    background-color: #2FA37A;
  }
  &:focus {
    background-color: #2FA37A;
  }
  &:focus:active {
    background-color: #3BBC8F;
  }
  &:disabled {
    cursor: default;
    background-color: #EAEBEA;
    color: #7C7C7C;
  }
}
